import { Message } from 'element-ui'
import Vue from 'vue'
let messageInstance = null
const OneMessage = (options) => {
  if (messageInstance) {
    messageInstance.close()
  }
  messageInstance = Message(options)
};
['error', 'success', 'info', 'warning'].forEach(type => {
  OneMessage[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options
      }
    }
    options.type = type
    return OneMessage(options)
  }
})
export default {
  install(vue) {
    Vue.prototype.$OneMessage = OneMessage
  }
}
