import axios from 'axios'
import {
  Message
} from 'element-ui'
import qs from 'qs'
import Vue from 'vue'
import router from '@/router/index.js'
import store from '@/store/index.js'

// create an axios instance
export const service = axios.create({
  baseURL: process.env.BASE_API, // api的base_url
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(config => {
  // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
  // config.headers['clientType'] = 'E'
  store.commit('showLoading')
  return config
}, error => {
  // Do something with request error
  // console.log(error) // for debug
  Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(
  response => {
    setTimeout(() => {
      store.commit('hideLoading')
    }, 500)
    const result = response.data
    if (result.success) {
      if (response.config.url.match(/\/addOrUpdate|delete$/g)) {
        Message({
          message: result.msg,
          type: 'success'
        })
      }
      Promise.resolve(result)
    } else if (result.msg === 'nologin') {
      Message({
        message: '登录超时，请重新登录',
        type: 'error',
        duration: 5 * 1000
      })
      setTimeout(() => {
        if (router.currentRoute.path.startsWith('/admin') || router.currentRoute.path.startsWith('/login')) {
          router.push('/login')
        } else {
          router.push('/index')
        }
      }, 2000)
      return Promise.reject('登录超时')
    } else {
      const msg = result.msg || '未知错误'
      Message({
        message: msg,
        type: 'error'
      })
      return Promise.reject(msg)
    }
    return result
  },
  error => {
    store.commit('hideLoading')
    if (error.message.includes('timeout')) {
      error.message = '请求超时'
    }
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export function post(url, params, config) {
  return service.post(url, qs.stringify(params), config)
}

export function postJson(url, data, config) {
  return service.post(url, data, config)
}

export function get(url, params) {
  return service.get(url, {
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  })
}

export default {
  install: (app) => {
    Vue.prototype.$get = get
    Vue.prototype.$post = postJson
    Vue.prototype.$postRow = post
  }
}
