<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  provide: {
    sysName: '工程施工管理系统',
  }
}

</script>

<style lang="scss">
* {
  box-sizing: content-box;
}
body {
	margin: 0px;
	padding: 0px;
	/*background: url(assets/bg1.jpg) center !important;
		background-size: cover;*/
	// background: #1F2D3D;
	font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;
	font-size: 14px;
	-webkit-font-smoothing: antialiased;
}

#app {
	position: absolute;
	top: 0px;
	bottom: 0px;
	width: 100%;
}

.el-submenu [class^=fa] {
	vertical-align: baseline;
	margin-right: 10px;
}

.el-menu-item [class^=fa] {
	vertical-align: baseline;
	margin-right: 10px;
}

.toolbar {
	background: #f2f2f2;
	padding: 10px 10px 0;
	border:1px solid #dfe6ec;
	//margin: 10px 0px;
	.el-form-item {
		margin-bottom: 10px;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: all .2s ease;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}

/*--------------全局搜索框设置--start------------*/
.fire_search .el-form-item .el-form-item__label {
	/*width: 140px;*/
	display: none;
}
// .fire_search .el-form-item__content input {
// /*width: 210px;*/
// }
// .fire_search .fire_search_btn {
// /*padding-left: 140px*/
// }
.fire_search .fire_search_btn button {
	/*border-radius: 20px;*/
	color: #fff;
	background-color: #409eff;
	border-color: #409eff;
	margin: 0 10px 0 0;
	/*medium样式*/
	padding: 10px 20px;
	font-size: 14px;
	border-radius: 4px;
}
.fire_search .fire_search_btn button:hover {
	/*border-radius: 20px;*/
	color: #fff;
	background-color: #409eff;
	border-color: #409eff;
	margin: 0 10px 0 0;
	/*medium样式*/
	padding: 10px 20px;
	font-size: 14px;
	border-radius: 4px;
}
/*--------------全局搜索框设置--end  ------------*/

/*-----------全局数据表格设置--start  -----------*/
.fire-data-table tr th {
    text-align: center;
}
.fire-data-table tr td {
    text-align: center;
}
/*-----------全局数据表格设置--end   -----------*/
</style>
