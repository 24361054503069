import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  { path: '/login', component: resolve => require(['@/views/Login.vue'], resolve) },
  { path: '/', component: resolve => require(['@/views/Login'], resolve) },
  { path: '/404', component: resolve => require(['@/views/404.vue'], resolve) },
  {
    path: '/admin',
    component: resolve => require(['@/views/Home.vue'], resolve),
    children: [
      { path: 'index', name: '首页', component: resolve => require(['@/views/Index.vue'], resolve) },
      { path: 'account', name: '用户管理', component: resolve => require(['@/views/admin/Account.vue'], resolve) },
      { path: 'project', name: '项目管理', component: resolve => require(['@/views/admin/Project.vue'], resolve) },
      { path: 'inspectAndConstruction', name: '综合施工', component: resolve => require(['@/views/admin/InspectAndContruction.vue'], resolve) },
      { path: 'inspect', name: '查勘管理', component: resolve => require(['@/views/admin/Inspect.vue'], resolve) },
      { path: 'construction', name: '施工管理', component: resolve => require(['@/views/admin/Construction.vue'], resolve) },
      { path: 'material', name: '项目设备', component: resolve => require(['@/views/admin/Material.vue'], resolve) },
      { path: 'completedStatistics', name: '已完工项目统计', component: resolve => require(['@/views/admin/CompletedStatistics.vue'], resolve) },
      { path: 'unfinishedStatistics', name: '未完工项目统计', component: resolve => require(['@/views/admin/UnfinishedStatistics.vue'], resolve) },
      { path: 'inspectDevice', name: '设备类型', component: resolve => require(['@/views/admin/InspectDevice.vue'], resolve) },
      { path: 'constructionDevice', name: '设备型号', component: resolve => require(['@/views/admin/ConstructionDevice.vue'], resolve) },
      { path: 'sysLog', name: '日志管理', component: resolve => require(['@/views/admin/SysLog.vue'], resolve) },
      { path: 'sysParam', name: '系统参数管理', component: resolve => require(['@/views/admin/SysParam.vue'], resolve) },
    ]
  },
  { path: '*', hidden: true, redirect: { path: '/404' } }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
