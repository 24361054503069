import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入 font-awesome
import 'font-awesome/css/font-awesome.min.css'
// 引入 axios
import axios from './common/request'
import oneMessage from './common/element/message'
import ElementUI from 'element-ui'
import ImageView from 'element-ui/packages/image/src/image-viewer'
import 'element-ui/lib/theme-chalk/index.css'
Vue.component('ImageView', ImageView)
Vue.use(ElementUI)
Vue.use(axios)
Vue.use(oneMessage)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
